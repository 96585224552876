<template>
  <div>
    <CCard>
      <CCardHeader color="info" text-color="white">
        Sales Agent
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="4">
            <CInput
                v-model="salesAgent.lastName"
                v-on:update:value="updateItem"
                :isValid="notNull"
                placeholder="Enter last name"
                description="Last name"
                :readonly="!allowEdit"
                v-c-tooltip="{content: tooltips.lastName? tooltips.lastName: '', delay: tooltipDelay}"
            />
          </CCol>
          <CCol sm="4">
            <multiselect
                :disabled="!allowEdit"
                v-model="salesAgent.salesOffice"
                @input="updateItem"
                :options="this.salesOffices ? this.salesOffices.data? this.salesOffices.data: [this.salesOffice]: [this.salesOffice]"
                :show-labels="true"
                label="name"
                track-by="name"
                :searchable="true"
                :close-on-select="true"
                :allow-empty="false"
                select-label="Select"
                deselect-label="Required field"
                placeholder="Sales office"
                v-on:search-change="updateSalesOfficeQ"
                :custom-label="customSalesOfficesLabel"
            />
            <CRow
                v-c-tooltip="{content: tooltips.salesOffice? tooltips.salesOffice: '', delay: tooltipDelay}"
            >
              <small class="form-text text-muted w-100">Sales office</small>
            </CRow>
          </CCol>
          <CCol sm="1">
            <CSwitch
                :disabled="!allowEdit"
                :checked.sync="salesAgent.activePapp"
                v-on:update:checked="updateItem"
                color="info"
                size="lg"
                variant="opposite"
                labelOn="Yes"
                labelOff="No"
                v-c-tooltip="{content: tooltips.activePapp? tooltips.activePapp: '', delay: tooltipDelay}"
            />
            <CRow
                v-c-tooltip="{content: tooltips.activePapp? tooltips.activePapp: '', delay: tooltipDelay}"
            >
              <small class="form-text text-muted w-100">Active</small>
            </CRow>
          </CCol>
          <CCol sm="1">
            <CSwitch
                :disabled="!allowEdit"
                :checked.sync="salesAgent.msActivePapp"
                v-on:update:checked="updateItem"
                color="info"
                size="lg"
                variant="opposite"
                labelOn="Yes"
                labelOff="No"
                v-c-tooltip="{content: tooltips.msActivePapp? tooltips.msActivePapp: '', delay: tooltipDelay}"
            />
            <CRow
                v-c-tooltip="{content: tooltips.msActivePapp? tooltips.msActivePapp: '', delay: tooltipDelay}"
            >
              <small class="form-text text-muted w-100">(DEPRECATED) MS Active</small>
            </CRow>
          </CCol>
          <CCol sm="1">
            <CSwitch
                :disabled="!allowEdit"
                :checked.sync="salesAgent.imActivePapp"
                v-on:update:checked="updateItem"
                color="info"
                size="lg"
                variant="opposite"
                labelOn="Yes"
                labelOff="No"
                v-c-tooltip="{content: tooltips.imActivePapp? tooltips.imActivePapp: '', delay: tooltipDelay}"
            />
            <CRow
                v-c-tooltip="{content: tooltips.imActivePapp? tooltips.imActivePapp: '', delay: tooltipDelay}"
            >
              <small class="form-text text-muted w-100">(DEPRECATED) IM Active</small>
            </CRow>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="5">
            <CInput
                v-model="salesAgent.firstName"
                v-on:update:value="updateItem"
                :isValid="notNull"
                placeholder="Enter first name"
                description="First name"
                :readonly="!allowEdit"
                v-c-tooltip="{content: tooltips.firstName? tooltips.firstName: '', delay: tooltipDelay}"
            />
          </CCol>
          <CCol sm="3">
            <multiselect
                :disabled="!allowEdit"
                v-model="salesAgent.gender"
                @input="updateItem"
                :options="['m', 'f']"
                :custom-label="(item) => this.genderLabels[item]"
                :searchable="false"
                :close-on-select="true"
                :allow-empty="true"
                placeholder="Select gender"
                select-label="Select"
                deselect-label="Remove"
            />
            <CRow
                v-c-tooltip="{content: tooltips.gender? tooltips.gender: '', delay: tooltipDelay}"
            >
              <small class="form-text text-muted w-100">Gender</small>
            </CRow>
          </CCol>
          <CCol sm="4">
            <CInput
                v-model="salesAgent.externalId"
                v-on:update:value="updateItem"
                placeholder="Enter external ID"
                description="External ID"
                :readonly="!allowEdit"
                @input="salesAgent.externalId = $event !== '' ? $event : null"
                v-c-tooltip="{content: tooltips.externalId? tooltips.externalId: '', delay: tooltipDelay}"
            />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <FormDetailSection
        section="Contact"
        v-bind:showSection="showContact"
        v-on:toggle="toggleContact"
    />
    <CCollapse
        :show.sync="showContact"
        :duration="1"
    >
      <CCard>
        <CCardHeader color="info" text-color="white">
          Contact Details
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="4">
              <CRow>
                <CCol sm="12">
                  <CImg
                      :src="salesAgent.avatar? salesAgent.avatar.hrefOrig: null"
                      :thumbnail="true"
                      width="50%"
                      :height="salesAgent.avatar? salesAgent.avatar.hrefOrig? '100%': '100px': '100px'"
                  />
                </CCol>
              </CRow>
              <CRow v-if="allowEdit">
                <CCol sm="12">
                  <CInputFile
                      v-on:change="uploadAvatar"
                      placeholder="Upload avatar"
                      description="Avatar"
                      :readonly="!allowEdit"
                      v-c-tooltip="{content: tooltips.avatar? tooltips.avatar: '', delay: tooltipDelay}"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol sm="8">
              <CRow>
                <CCol sm="12">
                  <CInput
                      v-model="salesAgent.email"
                      v-on:update:value="updateItem"
                      :isValid="validEmail"
                      placeholder="Enter email"
                      description="Email"
                      :readonly="!allowEdit"
                      @input="salesAgent.email = $event !== '' ? $event : null"
                      v-c-tooltip="{content: tooltips.email? tooltips.email: '', delay: tooltipDelay}"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="3">
                  <CInput
                      v-model="salesAgent.phoneCountryPrefix"
                      v-on:update:value="updateItem"
                      placeholder="Enter phone number country prefix"
                      description="Phone Country Prefix"
                      :readonly="!allowEdit"
                      @input="salesAgent.phoneCountryPrefix = $event !== '' ? $event : null"
                      v-c-tooltip="{content: tooltips.phoneCountryPrefix? tooltips.phoneCountryPrefix: '', delay: tooltipDelay}"
                  />
                </CCol>
                <CCol sm="9">
                  <CInput
                      v-model="salesAgent.phone"
                      v-on:update:value="updateItem"
                      placeholder="Enter phone number"
                      description="Phone"
                      :readonly="!allowEdit"
                      @input="salesAgent.phone = $event !== '' ? $event : null"
                      v-c-tooltip="{content: tooltips.phone? tooltips.phone: '', delay: tooltipDelay}"
                  />
                </CCol>
              </CRow>
              <AddressForm
                  v-bind:address-in="address"
                  v-bind:display-additional-address-line="false"
                  v-on:update-item="updateAddress"
              />
              <CRow>
                <CCol sm="12">
                  <CInput
                      v-model="salesAgent.position"
                      v-on:update:value="updateItem"
                      placeholder="Enter position"
                      description="Position"
                      :readonly="!allowEdit"
                      @input="salesAgent.position = $event !== '' ? $event : null"
                      v-c-tooltip="{content: tooltips.position? tooltips.position: '', delay: tooltipDelay}"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCollapse>
    <FormDetailSection
        section="Branding"
        v-bind:showSection="showBranding"
        v-on:toggle="toggleBranding"
    />
    <CCollapse
        :show.sync="showBranding"
        :duration="1"
    >
      <CCard>
        <CCardHeader color="info" text-color="white">
          Branding & Onpage Display Settings
        </CCardHeader>
        <CCardBody>
          <CCard>
            <CCardHeader color="secondary"
                   v-c-tooltip="{content: tooltips.displayRegions? tooltips.displayRegions: '', delay: tooltipDelay}"
            >
              <CRow>
                <CCol sm="2">
                  <CButton
                      v-if="allowEdit"
                      @click="handoverToDisplayRegions"
                      color="info"
                      variant="outline"
                      class="btn-sm"
                      v-c-tooltip="{content: 'Click to overwrite display with handover regions', delay: tooltipDelay}"
                  >Use Handover Regions
                  </CButton>
                </CCol>
                <CCol sm="10">
                  <div class="badge-pw">PW</div>
                  {{ salesAgent.displayRegions ? salesAgent.displayRegions.length : 0 }} Display
                  regions for PW
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <PostalCodeSelector
                  v-bind:country=null
                  v-bind:postal-codes-in="salesAgent? salesAgent.displayRegions: null"
                  v-on:update-item="(e) => {this.salesAgent.displayRegions = e; this.updateItem();}"
              />
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader color="secondary"
                   v-c-tooltip="{content: tooltips.displayRegions? tooltips.displayRegions: '', delay: tooltipDelay}"
            >
              <CRow>
                <CCol sm="2">
                  <CButton
                      v-if="allowEdit"
                      @click="handoverToMsDisplayRegions"
                      color="info"
                      variant="outline"
                      class="btn-sm"
                      v-c-tooltip="{content: 'Click to overwrite display with handover regions', delay: tooltipDelay}"
                  >Use Handover Regions
                  </CButton>
                </CCol>
                <CCol sm="10">
                  <div class="badge-ms">MS</div>
                  {{ salesAgent.msDisplayRegions ? salesAgent.msDisplayRegions.length : 0 }} Display
                  regions for MS
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <PostalCodeSelector
                  v-bind:country=null
                  v-bind:postal-codes-in="salesAgent? salesAgent.msDisplayRegions: null"
                  v-on:update-item="(e) => {this.salesAgent.msDisplayRegions = e; this.updateItem();}"
              />
            </CCardBody>
          </CCard>

          <CCard>
            <CCardHeader color="secondary"
                   v-c-tooltip="{content: tooltips.displayRegions? tooltips.displayRegions: '', delay: tooltipDelay}"
            >
              <CRow>
                <CCol sm="2">
                  <CButton
                      v-if="allowEdit"
                      @click="handoverToImDisplayRegions"
                      color="info"
                      variant="outline"
                      class="btn-sm"
                      v-c-tooltip="{content: 'Click to overwrite display with handover regions', delay: tooltipDelay}"
                  >Use Handover Regions
                  </CButton>
                </CCol>
                <CCol sm="10">
                  <div class="badge-im">IM</div>
                  {{ salesAgent.imDisplayRegions ? salesAgent.imDisplayRegions.length : 0 }} Display regions for IM
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <PostalCodeSelector
                  v-bind:country=null
                  v-bind:postal-codes-in="salesAgent? salesAgent.imDisplayRegions: null"
                  v-on:update-item="(e) => {this.salesAgent.imDisplayRegions = e; this.updateItem();}"
              />
            </CCardBody>
          </CCard>
        </CCardBody>
      </CCard>

    </CCollapse>
    <FormDetailSection
        section="Handover"
        v-bind:showSection="showHandover"
        v-on:toggle="toggleHandover"
    />
    <CCollapse
        :show.sync="showHandover"
        :duration="1"
    >
      <CCard>
        <CCardHeader color="info" text-color="white">
          Handover Settings
        </CCardHeader>
        <CCardBody>
          <CCard>
            <CCardHeader color="secondary">
              <div class="badge-pw">PW</div>
              Handover Options for PW
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="2">
                  <CRow class="justify-content-center switch">
                    <CSwitch
                        :disabled="!allowEdit"
                        :checked.sync="salesAgent.mandatoryMember"
                        v-on:update:checked="updateItem"
                        color="info"
                        size="lg"
                        variant="opposite"
                        labelOn="Yes"
                        labelOff="No"
                    />
                  </CRow>
                  <CRow>
                    <small class="form-text text-muted w-100">Mandatory member</small>
                  </CRow>
                </CCol>
                <CCol sm="10">
                  <CCard color="info" text-color="white">
                    <CCardBody>
                      <CCardText>
                        TODO: add info text for mandatory member
                      </CCardText>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader color="secondary"
                   v-c-tooltip="{content: tooltips.handoverRegions? tooltips.handoverRegions: '', delay: tooltipDelay}"
            >
              <div class="badge-pw">PW</div>
              {{ salesAgent ? salesAgent.handoverRegions ? salesAgent.handoverRegions.length : 0 : 0 }}
              Handover regions for PW
            </CCardHeader>
            <CCardBody>
              <PostalCodeSelector
                  v-bind:country=null
                  v-bind:postal-codes-in="salesAgent? salesAgent.handoverRegions: null"
                  v-on:update-item="(e) => {this.salesAgent.handoverRegions = e; this.updateItem();}"
              />
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader color="secondary"
                   v-c-tooltip="{content: tooltips.handoverRegions? tooltips.handoverRegions: '', delay: tooltipDelay}"
            >
              <div class="badge-ms">MS</div>
              {{ salesAgent ? salesAgent.msHandoverRegions ? salesAgent.msHandoverRegions.length : 0 : 0 }}
              Handover regions for MS
            </CCardHeader>
            <CCardBody>
              <PostalCodeSelector
                  v-bind:country=null
                  v-bind:postal-codes-in="salesAgent? salesAgent.msHandoverRegions: null"
                  v-on:update-item="(e) => {this.salesAgent.msHandoverRegions = e; this.updateItem();}"
              />
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader color="secondary"
                   v-c-tooltip="{content: tooltips.handoverRegions? tooltips.handoverRegions: '', delay: tooltipDelay}"
            >
              <div class="badge-im">IM</div>
              {{ salesAgent ? salesAgent.imHandoverRegions ? salesAgent.imHandoverRegions.length : 0 : 0 }}
              Handover regions for IM
            </CCardHeader>
            <CCardBody>
              <PostalCodeSelector
                  v-bind:country=null
                  v-bind:postal-codes-in="salesAgent? salesAgent.imHandoverRegions: null"
                  v-on:update-item="(e) => {this.salesAgent.imHandoverRegions = e; this.updateItem();}"
              />
            </CCardBody>
          </CCard>
        </CCardBody>
      </CCard>


    </CCollapse>
  </div>
</template>

<script>
import gql from "graphql-tag";
import FormDetailSection from "@/components/common/FormDetailSection";
import PostalCodeSelector from "@/apps/partners/components/PostalCodeSelector";
import AddressForm from "@/apps/partners/components/AddressForm";
import SalesAgentTooltips from "@/apps/partners/tooltips/sales_agent_tooltips";

const _ = require('lodash')

const querySalesOffices = gql`
query salesOffices($q: String){
  salesOffices(q: $q, order: "name") {
    data {
      nid
      name
    }
  }
}
`

export default {
  name: "SalesAgentForm",
  props: ["salesAgentIn", "salesOffice"],
  components: {
    FormDetailSection,
    PostalCodeSelector,
    AddressForm
  },
  data() {
    return {
      tooltips: SalesAgentTooltips,
      tooltipDelay: 500,
      salesOffices: [],
      salesOfficeQ: null,
      genderLabels: {f: 'female', m: 'male'},
      showContact: false,
      showBranding: false,
      showHandover: false,
    }
  },
  methods: {
    updateItem() {
      const data = _.cloneDeep(_.omit(this.salesAgent, ['avatar.__typename']))
      //multiselect object
      data.salesOffice = this.salesAgent.salesOffice ? {nid: this.salesAgent.salesOffice.nid} : null;
      data.displayRegions = this.salesAgent.displayRegions ? this.salesAgent.displayRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.msDisplayRegions = this.salesAgent.msDisplayRegions ? this.salesAgent.msDisplayRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.imDisplayRegions = this.salesAgent.imDisplayRegions ? this.salesAgent.imDisplayRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.handoverRegions = this.salesAgent.handoverRegions ? this.salesAgent.handoverRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.msHandoverRegions = this.salesAgent.msHandoverRegions ? this.salesAgent.msHandoverRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.imHandoverRegions = this.salesAgent.imHandoverRegions ? this.salesAgent.imHandoverRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.country = this.salesAgent.country ? {nid: this.salesAgent.country.nid} : null;
      data.postalCode = this.salesAgent.postalCode ? {nid: this.salesAgent.postalCode.nid} : null;
      this.$emit('update-item', data);
    },
    notNull(val) {
      return val ? val.length >= 1 : false
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    updateSalesOfficeQ(event) {
      this.salesOfficeQ = event
    },
    toggleContact() {
      this.showContact = !this.showContact
    },
    toggleBranding() {
      this.showBranding = !this.showBranding
    },
    toggleHandover() {
      this.showHandover = !this.showHandover
    },
    uploadAvatar(input) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.salesAgent.avatar = this.salesAgent.avatar ? this.salesAgent.avatar : {};
        this.salesAgent.avatar.base64 = e.target.result;
        this.salesAgent.avatar.name = input[0].name;
        this.updateItem();
      };
      reader.readAsDataURL(input[0]);
    },
    handoverToDisplayRegions() {
      this.salesAgent.displayRegions = this.salesAgent.handoverRegions ? _.cloneDeep(this.salesAgent.handoverRegions) : [];
      this.updateItem();
    },
    handoverToMsDisplayRegions() {
      this.salesAgent.msDisplayRegions = this.salesAgent.msHandoverRegions ? _.cloneDeep(this.salesAgent.msHandoverRegions) : [];
      this.updateItem();
    },
    handoverToImDisplayRegions() {
      this.salesAgent.imDisplayRegions = this.salesAgent.imHandoverRegions ? _.cloneDeep(this.salesAgent.imHandoverRegions) : [];
      this.updateItem();
    },
    updateAddress(address) {
      this.salesAgent['addressLine1'] = address['addressLine1'];
      this.salesAgent['postalCode'] = address['postalCode'];
      this.salesAgent['city'] = address['city'];
      this.salesAgent['country'] = address['country'];
      this.updateItem();
    },
    customSalesOfficesLabel({name, nid}) {
      return `${name} (${nid})`
    },
  },
  apollo: {
    salesOffices: {
      query: querySalesOffices,
      variables() {
        return {
          q: this.salesOfficeQ
        }
      },
      error(error) {
        console.error(error);
        this.$emit("error", JSON.stringify(error));
      }
    },
  },
  computed: {
    salesAgent() {
      return this.salesAgentIn ? this.salesAgentIn : {}
    },
    address() {
      return {
        addressLine1: this.salesAgent ? this.salesAgent.addressLine1 : null,
        postalCode: this.salesAgent ? this.salesAgent.postalCode : null,
        city: this.salesAgent ? this.salesAgent.city : null,
        country: this.salesAgent ? this.salesAgent.country : null,
      }
    },
    allowEdit: {
      get() {
        return this.$store.state.allowEdit
      },
      set() {
      }
    }
  },
  watch: {
    salesAgentIn() {
      this.updateItem();
    },
    salesAgent() {
      this.updateItem();
    }
  }
}
</script>

<style scoped>
.multiselect {
  border-width: 2pt;;
  list-style-type: none;
  margin-bottom: 1pt;
}

.ms-row {
  margin-bottom: .75em;
}

.switch {
  margin-bottom: .2em;
  margin-top: .15em;
}
.badge-ms {
  background-color: #9c0725 !important;
  white-space: normal !important;
  color: #fff !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-pw {
  background-color: #0083aa !important;
  white-space: normal !important;
  color: #fff !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-im {
  background-color: #fc0 !important;
  white-space: normal !important;
  color: #303038 !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
