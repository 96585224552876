<template>
  <div>
    <CCard>
      <CCardHeader color="info" text-color="white">
        Sales Office
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="5">
            <CInput
                v-model="salesOffice.name"
                v-on:update:value="updateItem"
                :isValid="notNull"
                placeholder="Enter name"
                description="Name"
                :readonly="!allowEdit"
                v-c-tooltip="{content: tooltips.name? tooltips.name: '', delay: tooltipDelay}"
            />
          </CCol>
          <CCol sm="3">
            <multiselect
                :disabled="!allowEdit"
                v-model="salesOffice.vendor"
                @input="updateItem"
                :options="this.vendors ? this.vendors.data? this.vendors.data: []: []"
                :show-labels="true"
                label="name"
                track-by="name"
                :searchable="true"
                :close-on-select="true"
                :allow-empty="false"
                select-label="Select"
                deselect-label="Required field"
                placeholder="Vendor"
                v-on:search-change="updateVendorQ"
            />
            <CRow
                v-c-tooltip="{content: tooltips.vendor? tooltips.vendor: '', delay: tooltipDelay}"
            >
              <small class="form-text text-muted w-100">Vendor</small>
            </CRow>
          </CCol>
          <CCol sm="1">
            <CRow class="justify-content-center switch">
              <CSwitch
                  :disabled="!allowEdit"
                  :checked.sync="salesOffice.activePapp"
                  v-on:update:checked="updateItem"
                  color="info"
                  size="lg"
                  variant="opposite"
                  labelOn="Yes"
                  labelOff="No"
                  v-c-tooltip="{content: tooltips.activePapp? tooltips.activePapp: '', delay: tooltipDelay}"
              />
            </CRow>
            <CRow
                v-c-tooltip="{content: tooltips.activePapp? tooltips.activePapp: '', delay: tooltipDelay}"
            >
              <small class="form-text text-muted w-100">Active</small>
            </CRow>
          </CCol>
          <CCol sm="1">
            <CRow class="justify-content-center switch">
              <CSwitch
                  :disabled="!allowEdit"
                  :checked.sync="salesOffice.msActivePapp"
                  v-on:update:checked="updateItem"
                  color="info"
                  size="lg"
                  variant="opposite"
                  labelOn="Yes"
                  labelOff="No"
                  v-c-tooltip="{content: tooltips.msActivePapp? tooltips.msActivePapp: '', delay: tooltipDelay}"
              />
            </CRow>
            <CRow
                v-c-tooltip="{content: tooltips.msActivePapp? tooltips.msActivePapp: '', delay: tooltipDelay}"
            >
              <small class="form-text text-muted w-100">(DEPRECATED) MS Active</small>
            </CRow>
          </CCol>
          <CCol sm="1">
            <CRow class="justify-content-center switch">
              <CSwitch
                  :disabled="!allowEdit"
                  :checked.sync="salesOffice.imActivePapp"
                  v-on:update:checked="updateItem"
                  color="info"
                  size="lg"
                  variant="opposite"
                  labelOn="Yes"
                  labelOff="No"
                  v-c-tooltip="{content: tooltips.imActivePapp? tooltips.imActivePapp: '', delay: tooltipDelay}"
              />
            </CRow>
            <CRow
                v-c-tooltip="{content: tooltips.imActivePapp? tooltips.imActivePapp: '', delay: tooltipDelay}"
            >
              <small class="form-text text-muted w-100">(DEPRECATED) IM Active</small>
            </CRow>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="3">
            <multiselect
                :disabled="!allowEdit"
                v-model="salesOffice.officeType"
                @input="updateItem"
                :options="['model_house', 'selling_agency', 'headquarter', 'factory', 'sample_provisioning']"
                :searchable="false"
                :close-on-select="true"
                :allow-empty="false"
                placeholder="Select plan"
                select-label="Select"
                deselect-label="Required field"
            />
            <CRow
                v-c-tooltip="{content: tooltips.officeType? tooltips.officeType: '', delay: tooltipDelay}"
            >
              <small class="form-text text-muted w-100">Office type</small>
            </CRow>
          </CCol>
          <CCol sm="3">
            <multiselect
                :disabled="!allowEdit"
                v-model="salesOffice.plan"
                @input="updateItem"
                :options="['free', 'premium']"
                :searchable="false"
                :close-on-select="true"
                :allow-empty="false"
                placeholder="Select plan"
                select-label="Select"
                deselect-label="Required field"
            />
            <CRow
                v-c-tooltip="{content: tooltips.plan? tooltips.plan: '', delay: tooltipDelay}"
            >
              <small class="form-text text-muted w-100">(DEPRECATED) Plan</small>
            </CRow>
          </CCol>
          <CCol sm="2">
            <CInput
                v-model="salesOffice.externalId"
                v-on:change="updateItem"
                placeholder="Enter external ID"
                description="External ID"
                :readonly="!allowEdit"
                @input="salesOffice.externalId = $event !== '' ? $event : null"
                v-c-tooltip="{content: tooltips.externalId? tooltips.externalId: '', delay: tooltipDelay}"
            />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <FormDetailSection
        section="Sales Agents"
        v-bind:showSection="showSalesAgents"
        v-on:toggle="toggleSalesAgents"
    />
    <CCollapse
        :show.sync="showSalesAgents"
        :duration="1"
    >
      <CCard>
        <CCardHeader color="info" text-color="white">
          Sales Agents {{ salesOffice ? salesOffice.name ? 'of ' + salesOffice.name : '' : '' }}
        </CCardHeader>
        <CCardBody>
          <SalesAgents
              :sales-office="salesOffice"
              v-on:error="catchError"
          />
        </CCardBody>
      </CCard>

    </CCollapse>
    <FormDetailSection
        section="Contact & Address"
        v-bind:showSection="showContact"
        v-on:toggle="toggleContact"
    />
    <CCollapse
        :show.sync="showContact"
        :duration="1"
    >
      <CCard>
        <CCardHeader color="info" text-color="white">
          Contact & Address details
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="12">
              <CInput
                  v-model="salesOffice.company"
                  v-on:change="updateItem"
                  placeholder="Enter company"
                  description="Company"
                  :readonly="!allowEdit"
                  @input="salesOffice.company = $event !== '' ? $event : null"
                  v-c-tooltip="{content: tooltips.company? tooltips.company: '', delay: tooltipDelay}"
              />
            </CCol>
          </CRow>
          <AddressForm
              v-bind:address-in="address"
              v-bind:display-additional-address-line="true"
              v-on:update-item="updateAddress"
          />
          <CRow>
            <CCol sm="6">
              <CInput
                  v-model="salesOffice.website"
                  v-on:change="updateItem"
                  placeholder="Enter homepage"
                  description="Homepage"
                  :readonly="!allowEdit"
                  @input="salesOffice.website = $event !== '' ? $event : null"
                  v-c-tooltip="{content: tooltips.website? tooltips.website: '', delay: tooltipDelay}"
              />
            </CCol>
            <CCol sm="6">
              <CInput
                  v-model="salesOffice.officePhone"
                  v-on:change="updateItem"
                  placeholder="Enter phone number"
                  description="Phone"
                  :readonly="!allowEdit"
                  @input="salesOffice.officePhone = $event !== '' ? $event : null"
                  v-c-tooltip="{content: tooltips.officePhone? tooltips.officePhone: '', delay: tooltipDelay}"
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCollapse>
    <FormDetailSection
        section="Branding & Display"
        v-bind:showSection="showBranding"
        v-on:toggle="toggleBranding"
    />
    <CCollapse
        :show.sync="showBranding"
        :duration="1"
    >
      <CCard>
        <CCardHeader color="info" text-color="white">
          Branding & Onpage Display Settings
        </CCardHeader>
        <CCardBody>
          <CCard>
            <CCardHeader color="secondary">
              <div class="badge-pw">PW</div>
              Branding Settings for PW
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="3">
                  <br/>
                  <CRow class="justify-content-center switch" data-sel="btn-vtp"
                  >
                    <CSwitch
                        :disabled="!allowEdit"
                        :checked.sync="salesOffice.disableRegionalBranding"
                        v-on:update:checked="updateItem"
                        color="info"
                        size="lg"
                        variant="opposite"
                        labelOn="Yes"
                        labelOff="No"
                        v-c-tooltip="{content: tooltips.disableRegionalBranding? tooltips.disableRegionalBranding: '', delay: tooltipDelay}"
                    />
                  </CRow>
                  <CRow
                      v-c-tooltip="{content: tooltips.disableRegionalBranding? tooltips.disableRegionalBranding: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">Regional branding</small>
                  </CRow>
                </CCol>
              </CRow>

            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader color="secondary">
              Onpage Display Information
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="2">
                  <CRow>
                    <CCol class="text-left">
                      <CImg
                          :src="salesOffice.officeProfilePicture? salesOffice.officeProfilePicture.hrefOrig: null"
                          :thumbnail="true"
                          width="100%"
                          :height="salesOffice.officeProfilePicture? salesOffice.officeProfilePicture.hrefOrig? '100%': '130px': '130px'"
                          v-c-tooltip="{content: tooltips.officeProfilePicture? tooltips.officeProfilePicture: '', delay: tooltipDelay}"
                      />
                    </CCol>
                  </CRow>
                  <CRow v-if="allowEdit">
                    <CCol class="mt-2">
                      <CInputFile
                          v-if="allowEdit"
                          v-on:change="uploadOfficeProfilePicture"
                          placeholder="Upload Office Profile Picture"
                          :readonly="!allowEdit"
                          v-c-tooltip="{content: tooltips.officeProfilePicture? tooltips.officeProfilePicture: '', delay: tooltipDelay}"
                      />
                    </CCol>
                  </CRow>
                  <CRow v-if="allowEdit">
                    <CCol class="text-left">
                      <CButton
                          v-if="allowEdit"
                          @click="deleteOfficeProfilePicture"
                          color="info"
                          variant="outline"
                          class="btn-sm"
                          v-c-tooltip="{content: 'Delete the Office Profile Picture', delay: tooltipDelay}"
                      >
                        Delete Picture
                      </CButton>
                    </CCol>
                  </CRow>
                  <CRow
                      v-c-tooltip="{content: tooltips.officeProfilePicture? tooltips.officeProfilePicture: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">Office Profile Picture</small>
                  </CRow>
                </CCol>
                <CCol sm="4">
                  <multiselect
                      class="services-multiselect"
                      :disabled="!allowEdit"
                      v-model="salesOffice.services"
                      @tag="addService"
                      @remove="removeService"
                      :options="salesOffice? salesOffice.services? salesOffice.services: []: []"
                      :taggable="true"
                      tag-placeholder="Add this service"
                      placeholder="Add services"
                      :multiple="true"
                      :maxHeight="200"
                  />
                  <CRow
                      v-c-tooltip="{content: tooltips.services? tooltips.services: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">Custom Services</small>
                  </CRow>
                </CCol>
                <CCol sm="6">
                  <CTextarea
                      rows="6"
                      v-model="salesOffice.description"
                      :readonly="!allowEdit"
                      v-on:change="updateItem"
                      description="Description"
                      placeholder="Enter description"
                      v-c-tooltip="{content: tooltips.description? tooltips.description: '', delay: tooltipDelay}"
                      @input="salesOffice.description = $event !== '' ? $event : null"
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>


          <CCard>
            <CCardHeader color="secondary">
              <CRow>
                <CCol sm="2">
                  <CButton
                      v-if="allowEdit"
                      @click="handoverToDisplayRegions"
                      color="info"
                      variant="outline"
                      class="btn-sm"
                      v-c-tooltip="{content: 'Click to overwrite display with handover regions', delay: tooltipDelay}"
                  >Use Handover Regions
                  </CButton>
                </CCol>
                <CCol
                    sm="10"
                    v-c-tooltip="{content: tooltips.displayRegions? tooltips.displayRegions: '', delay: tooltipDelay}"
                >
                  <div class="badge-pw">PW</div>
                  {{ salesOffice ? salesOffice.displayRegions ? salesOffice.displayRegions.length : 0 : 0 }}
                  Display regions for PW
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <PostalCodeSelector
                  v-bind:country="salesOffice? salesOffice.vendor ? salesOffice.vendor.operatesIn : null : null"
                  v-bind:postal-codes-in="salesOffice? salesOffice.displayRegions: null"
                  v-on:update-item="(e) => {this.salesOffice.displayRegions = e; this.updateItem();}"
              />
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader color="secondary">
              <CRow>
                <CCol sm="2">
                  <CButton
                      v-if="allowEdit"
                      @click="handoverToMsDisplayRegions"
                      color="info"
                      variant="outline"
                      class="btn-sm"
                      v-c-tooltip="{content: 'Click to overwrite display with handover regions', delay: tooltipDelay}"
                  >Use Handover Regions
                  </CButton>
                </CCol>
                <CCol
                    sm="10"
                    v-c-tooltip="{content: tooltips.displayRegions? tooltips.displayRegions: '', delay: tooltipDelay}"
                >
                  <div class="badge-ms">MS</div>
                  {{ salesOffice ? salesOffice.msDisplayRegions ? salesOffice.msDisplayRegions.length : 0 : 0 }}
                  Display regions for MS
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <PostalCodeSelector
                  v-bind:country="salesOffice? salesOffice.vendor ? salesOffice.vendor.operatesIn: null: null"
                  v-bind:postal-codes-in="salesOffice? salesOffice.msDisplayRegions: null"
                  v-on:update-item="(e) => {this.salesOffice.msDisplayRegions = e; this.updateItem();}"
              />
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader color="secondary">
              <CRow>
                <CCol sm="2">
                  <CButton
                      v-if="allowEdit"
                      @click="handoverToImDisplayRegions"
                      color="info"
                      variant="outline"
                      class="btn-sm"
                      v-c-tooltip="{content: 'Click to overwrite display with handover regions', delay: tooltipDelay}"
                  >Use Handover Regions
                  </CButton>
                </CCol>
                <CCol
                    sm="10"
                    v-c-tooltip="{content: tooltips.imDisplayRegions? tooltips.imDisplayRegions: '', delay: tooltipDelay}"
                >
                  <div class="badge-im">IM</div>
                  {{ salesOffice ? salesOffice.imDisplayRegions ? salesOffice.imDisplayRegions.length : 0 : 0 }}
                  Display regions for IM
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <PostalCodeSelector
                  v-bind:country="salesOffice? salesOffice.vendor ? salesOffice.vendor.operatesIn: null: null"
                  v-bind:postal-codes-in="salesOffice? salesOffice.imDisplayRegions: null"
                  v-on:update-item="(e) => {this.salesOffice.imDisplayRegions = e; this.updateItem();}"
              />
            </CCardBody>
          </CCard>
          <CCard>
            <CCardHeader color="secondary">
              Opening Hours
            </CCardHeader>
            <CCardBody>
              <div v-for="item in openingHours" :key="item.weekday">
                <OpeningHoursForm
                    v-bind:opening-hours-in="setDefaultOpeningHours(item)"
                    v-on:update-item="updateOpeningHours"
                />
              </div>
            </CCardBody>
          </CCard>
        </CCardBody>
      </CCard>
    </CCollapse>
    <FormDetailSection
        section="Handover"
        v-bind:showSection="showHandover"
        v-on:toggle="toggleHandover"
    />
    <CCollapse
        :show.sync="showHandover"
        :duration="1"
    >
      <CCard>
        <CCardHeader color="info" text-color="white">
          Handover Settings
        </CCardHeader>
        <CCardBody>
          <CCard>
            <CCardHeader color="secondary">
              <div class="badge-pw">PW</div>
              Handover Settings for PW
            </CCardHeader>
            <CCardBody>
              <CRow>

                <CCol sm="3">
                  <multiselect
                      :disabled="!allowEdit"
                      v-model="salesOffice.handoverMethod"
                      @input="updateItem"
                      :options="['office', 'agent']"
                      :searchable="false"
                      :close-on-select="true"
                      :allow-empty="false"
                      placeholder="Select handover method"
                      select-label="Select"
                      deselect-label="Required field"
                  />
                  <CRow
                      v-c-tooltip="{content: tooltips.handoverMethod? tooltips.handoverMethod: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">Handover method</small>
                  </CRow>
                </CCol>
                <CCol sm="3">
                  <CInput
                      v-model="salesOffice.officeHandoverEmail"
                      :isValid="validEmail"
                      v-on:change="updateItem"
                      placeholder="Enter handover email"
                      description="Handover email"
                      :readonly="!allowEdit"
                      @input="salesOffice.officeHandoverEmail = $event !== '' ? $event : null"
                      v-c-tooltip="{content: tooltips.officeHandoverEmail? tooltips.officeHandoverEmail: '', delay: tooltipDelay}"
                  />
                </CCol>
                <CCol sm="6">
                  <CCard color="info" text-color="white">
                    <CCardBody>
                      <CCardText>
                        If the "handover method" is set to office, then the handover regions
                        need to be added to the office and will automatically be synced to the
                        mandatory member (if existing).
                      </CCardText>
                      <CCardText>
                        If the "handover method" is set to agent, then the handover regions need
                        to be added to the sales agents and will automatically be synced to the
                        office.
                      </CCardText>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>

        </CCardBody>
      </CCard>


      <CCard>
        <CCardHeader color="secondary"
               v-c-tooltip="{content: tooltips.handoverRegions? tooltips.handoverRegions: '', delay: tooltipDelay}"
        >
          <div class="badge-pw">PW</div>
          {{ salesOffice ? salesOffice.handoverRegions ? salesOffice.handoverRegions.length : 0 : 0 }}
          Handover regions for PW
        </CCardHeader>
        <CCardBody>
          <PostalCodeSelector
              v-bind:country="salesOffice? salesOffice.vendor ? salesOffice.vendor.operatesIn: null: null"
              v-bind:postal-codes-in="salesOffice? salesOffice.handoverRegions: null"
              v-on:update-item="(e) => {this.salesOffice.handoverRegions = e; this.updateItem();}"
          />
        </CCardBody>
      </CCard>

      <CCard>
        <CCardHeader color="secondary">
          <div class="badge-ms">MS</div>
          Handover Settings for MS
        </CCardHeader>
        <CCardBody>
          <CRow class="handover-spec-row">
            <CCol sm=2>
              <CRow class="justify-content-center switch" data-sel="btn-msho-inst">
                <CSwitch
                    :disabled="!allowEdit"
                    :checked.sync="salesOffice.msSendHtmlInstantHandover"
                    v-on:update:checked="updateItem"
                    color="info"
                    size="lg"
                    variant="opposite"
                    labelOn="Yes"
                    labelOff="No"
                />
              </CRow>
              <CRow
                  v-c-tooltip="{content: tooltips.msSendHtmlInstantHandover? tooltips.msSendHtmlInstantHandover: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">MS instant HTML email handover</small>
              </CRow>
            </CCol>
            <CCol sm="10" v-if="salesOffice.msSendHtmlInstantHandover">
              <CRow>
                <CCol sm="3">
                  <multiselect
                      data-sel="slct-msho-inst-tmpl"
                      :disabled="!allowEdit"
                      v-model="salesOffice.msHtmlInstantHandoverTemplate"
                      @input="updateItem"
                      :options=this.msHtmlInstantHandoverTemplateOptions
                      :searchable="false"
                      :close-on-select="true"
                      :allow-empty="true"
                      placeholder="Select template"
                      select-label="Select"
                      deselect-label="Remove"
                  />
                  <CRow
                      v-c-tooltip="{content: tooltips.msHtmlInstantHandoverTemplate? tooltips.msHtmlInstantHandoverTemplate: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">MS instant HTML handover email
                      template</small>
                  </CRow>
                </CCol>
                <CCol sm="5">
                  <multiselect
                      data-sel="slct-msho-inst-email"
                      :disabled="!allowEdit"
                      v-model="salesOffice.msHtmlInstantHandoverEmails"
                      @input="updateItem"
                      :options="msHtmlInstantHandoverEmails"
                      :taggable="true"
                      tag-placeholder="Add this email"
                      placeholder="Add MS instant HTML email handover recipients"
                      :multiple="true"
                      @tag="addMsHtmlInstantHandoverEmail"
                  />
                  <CRow
                      v-c-tooltip="{content: tooltips.msHtmlInstantHandoverEmails? tooltips.msHtmlInstantHandoverEmails: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">MS instant HTML handover email
                      recipients</small>
                  </CRow>
                </CCol>
                <CCol sm="4">
                  <CInput
                      data-sel="ipt-msho-sbjt"
                      v-model="salesOffice.msHtmlInstantHandoverEmailSubject"
                      v-on:change="updateItem"
                      placeholder="Enter email subject"
                      description="MS instant HTML handover email subject"
                      tooltip-feedback
                      :readonly="!allowEdit"
                      v-c-tooltip="{content: tooltips.msHtmlInstantHandoverEmailSubject? tooltips.msHtmlInstantHandoverEmailSubject: '', delay: tooltipDelay}"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>

          <CRow class="handover-spec-row">
            <CCol sm=2>
              <CRow class="justify-content-center switch" data-sel="btn-msho-csv">
                <CSwitch
                    :disabled="!allowEdit"
                    :checked.sync="salesOffice.msSendCsvHandover"
                    v-on:update:checked="updateItem"
                    color="info"
                    size="lg"
                    variant="opposite"
                    labelOn="Yes"
                    labelOff="No"
                />
              </CRow>
              <CRow
                  v-c-tooltip="{content: tooltips.msSendCsvHandover? tooltips.msSendCsvHandover: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">MS CSV email handover</small>
              </CRow>
            </CCol>
            <CCol sm="10" v-if="salesOffice.msSendCsvHandover">
              <CRow>
                <CCol sm="5">
                  <multiselect
                      data-sel="slct-msho-csv-scdl"
                      :disabled="!allowEdit"
                      v-model="salesOffice.msCsvHandoverSchedule"
                      @input="updateItem"
                      :options=this.msCsvHandoverScheduleOptions
                      :searchable="false"
                      :close-on-select="true"
                      :allow-empty="true"
                      placeholder="Select schedule"
                      select-label="Select"
                      deselect-label="Remove"
                  />
                  <CRow
                      v-c-tooltip="{content: tooltips.msCsvHandoverSchedule? tooltips.msCsvHandoverSchedule: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">MS CSV handover email schedule</small>
                  </CRow>
                </CCol>
                <CCol sm="5">
                  <multiselect
                      data-sel="slct-msho-csv-scdl"
                      :disabled="!allowEdit"
                      v-model="salesOffice.msCsvHandoverFormat"
                      @input="updateItem"
                      :options=this.msCsvHandoverFormatOptions
                      :searchable="false"
                      :close-on-select="true"
                      :allow-empty="true"
                      placeholder="Select format"
                      select-label="Select"
                      deselect-label="Remove"
                  />
                  <CRow
                      v-c-tooltip="{content: tooltips.msCsvHandoverFormat? tooltips.msCsvHandoverFormat: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">MS CSV handover email CSV
                      format</small>
                  </CRow>
                </CCol>
              </CRow>
            </CCol>
          </CRow>

          <CRow class="handover-spec-row">
            <CCol sm="3" v-if="salesOffice.msSendCsvHandover">
              <multiselect
                  data-sel="slct-msho-csv-tmpl"
                  :disabled="!allowEdit"
                  v-model="salesOffice.msCsvHandoverTemplate"
                  @input="updateItem"
                  :options=this.msCsvHandoverTemplateOptions
                  :searchable="false"
                  :close-on-select="true"
                  :allow-empty="true"
                  placeholder="Select template"
                  select-label="Select"
                  deselect-label="Remove"
              />
              <CRow
                  v-c-tooltip="{content: tooltips.msCsvHandoverTemplate? tooltips.msCsvHandoverTemplate: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">MS CSV handover email template</small>
              </CRow>
            </CCol>
            <CCol sm="5" v-if="salesOffice.msSendCsvHandover">
              <multiselect
                  data-sel="slct-msho-inst-email"
                  :disabled="!allowEdit"
                  v-model="salesOffice.msCsvHandoverEmails"
                  @input="updateItem"
                  :options="msCsvHandoverEmails"
                  :taggable="true"
                  tag-placeholder="Add this email"
                  placeholder="Add MS CSV email handover recipients"
                  :multiple="true"
                  @tag="addMsCsvHandoverEmail"
              />
              <CRow
                  v-c-tooltip="{content: tooltips.msCsvHandoverEmails? tooltips.msCsvHandoverEmails: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">MS CSV handover email recipients</small>
              </CRow>
            </CCol>
            <CCol sm="4" v-if="salesOffice.msSendCsvHandover">
              <CInput
                  data-sel="ipt-msho-sbjt"
                  v-model="salesOffice.msCsvHandoverEmailSubject"
                  v-on:change="updateItem"
                  placeholder="Enter email subject"
                  description="MS CSV handover email subject"
                  tooltip-feedback
                  :readonly="!allowEdit"
                  v-c-tooltip="{content: tooltips.msCsvHandoverEmailSubject? tooltips.msCsvHandoverEmailSubject: '', delay: tooltipDelay}"
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader color="secondary"
               v-c-tooltip="{content: tooltips.handoverRegions? tooltips.handoverRegions: '', delay: tooltipDelay}"
        >
          <div class="badge-ms">MS</div>
          {{ salesOffice ? salesOffice.msHandoverRegions ? salesOffice.msHandoverRegions.length : 0 : 0 }}
          Handover regions for MS
        </CCardHeader>
        <CCardBody>
          <PostalCodeSelector
              v-bind:country="salesOffice? salesOffice.vendor ? salesOffice.vendor.operatesIn: null: null"
              v-bind:postal-codes-in="salesOffice? salesOffice.msHandoverRegions: null"
              v-on:update-item="(e) => {this.salesOffice.msHandoverRegions = e; this.updateItem();}"
          />
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader color="secondary"
               v-c-tooltip="{content: tooltips.imHandoverRegions? tooltips.imHandoverRegions: '', delay: tooltipDelay}"
        >
          <div class="badge-im">IM</div>
          {{ salesOffice ? salesOffice.imHandoverRegions ? salesOffice.imHandoverRegions.length : 0 : 0 }}
          Handover regions for IM
        </CCardHeader>
        <CCardBody>
          <PostalCodeSelector
              v-bind:country="salesOffice? salesOffice.vendor ? salesOffice.vendor.operatesIn: null: null"
              v-bind:postal-codes-in="salesOffice? salesOffice.imHandoverRegions: null"
              v-on:update-item="(e) => {this.salesOffice.imHandoverRegions = e; this.updateItem();}"
          />
        </CCardBody>
      </CCard>
    </CCollapse>

    <FormDetailSection
        section="Reporting"
        v-bind:showSection="showReporting"
        v-on:toggle="toggleReporting"
    />
    <CCollapse
        :show.sync="showReporting"
        :duration="1"
    >
      <CCard>
        <CCardHeader color="info" text-color="white">
          Reporting
        </CCardHeader>
        <CCardBody>
          <CCard>
            <CCardHeader color="secondary">Sales Office Reporting</CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="6">
                  <CInput
                      v-model="salesOffice.headOfSalesFirstName"
                      v-on:update:value="updateItem"
                      placeholder="Enter first name"
                      description="Head of Sales first name"
                      :readonly="!allowEdit"
                      @input="salesOffice.headOfSalesFirstName = $event !== '' ? $event : null"
                      v-c-tooltip="{content: tooltips.headOfSalesFirstName? tooltips.headOfSalesFirstName: '', delay: tooltipDelay}"
                  />
                </CCol>
                <CCol sm="6">
                  <CInput
                      v-model="salesOffice.headOfSalesLastName"
                      v-on:update:value="updateItem"
                      placeholder="Enter last name"
                      description="Head of Sales last name"
                      :readonly="!allowEdit"
                      @input="salesOffice.headOfSalesLastName = $event !== '' ? $event : null"
                      v-c-tooltip="{content: tooltips.headOfSalesLastName? tooltips.headOfSalesLastName: '', delay: tooltipDelay}"
                  />
                </CCol>
              </CRow>
              <CRow>

                <CCol sm="6">
                  <multiselect
                      :disabled="!allowEdit"
                      v-model="salesOffice.headOfSalesGender"
                      @input="updateItem"
                      :options="['m', 'f']"
                      :custom-label="(item) => this.genderLabels[item]"
                      :searchable="false"
                      :close-on-select="true"
                      :allow-empty="true"
                      placeholder="Select gender"
                      select-label="Select"
                      deselect-label="Remove"
                  />
                  <CRow
                      v-c-tooltip="{content: tooltips.headOfSalesGender? tooltips.headOfSalesGender: '', delay: tooltipDelay}"
                  >
                    <small class="form-text text-muted w-100">Head of Sales gender</small>
                  </CRow>
                </CCol>
                <CCol sm="6">
                  <CInput
                      v-model="salesOffice.headOfSalesEmail"
                      v-on:update:value="updateItem"
                      placeholder="Enter Email"
                      description="Head of Sales email"
                      :readonly="!allowEdit"
                      @input="salesOffice.headOfSalesEmail = $event !== '' ? $event : null"
                      v-c-tooltip="{content: tooltips.headOfSalesEmail? tooltips.headOfSalesEmail: '', delay: tooltipDelay}"
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCardBody>
      </CCard>
    </CCollapse>

    <FormDetailSection
        data-sel="btn-sctn-im"
        section="Immowelt"
        v-bind:showSection="showImmowelt"
        v-on:toggle="toggleImmowelt"
    />
    <CCollapse
        :show.sync="showImmowelt"
        :duration="1"
    >
      <CCard>
        <CCardHeader color="info" text-color="white">
          Immowelt Settings
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol sm="3">
              <CInput
                  data-sel="ipt-im-rgn"
                  v-model="salesOffice.imRegion"
                  v-on:change="updateItem"
                  placeholder="Enter IM region"
                  description="IM region description"
                  tooltip-feedback
                  :readonly="!allowEdit"
                  v-c-tooltip="{content: tooltips.imRegion? tooltips.imRegion: '', delay: tooltipDelay}"
              />
            </CCol>
            <CCol sm="3">
              <CInput
                  data-sel="ipt-im-lsturl"
                  v-model="salesOffice.imListingsUrl"
                  v-on:change="updateItem"
                  placeholder="Enter url"
                  description="IM listings url"
                  tooltip-feedback
                  :readonly="!allowEdit"
                  v-c-tooltip="{content: tooltips.imListingsUrl? tooltips.imListingsUrl: '', delay: tooltipDelay}"
              />
            </CCol>
            <CCol sm=2>
              <CRow class="justify-content-center switch" data-sel="btn-im-idxd">
                <CSwitch
                    :disabled="!allowEdit"
                    :checked.sync="salesOffice.imIndexed"
                    v-on:update:checked="updateItem"
                    color="info"
                    size="lg"
                    variant="opposite"
                    labelOn="Yes"
                    labelOff="No"
                />
              </CRow>
              <CRow
                  v-c-tooltip="{content: tooltips.imIndexed? tooltips.imIndexed: '', delay: tooltipDelay}"
              >
                <small class="form-text text-muted w-100">IM Indexed</small>
              </CRow>
            </CCol>

          </CRow>
        </CCardBody>
      </CCard>
    </CCollapse>

  </div>
</template>

<script>
import gql from "graphql-tag";
import FormDetailSection from "@/components/common/FormDetailSection";
import PostalCodeSelector from "@/apps/partners/components/PostalCodeSelector";
import OpeningHoursForm from "@/apps/partners/components/OpeningHoursForm";
import AddressForm from "@/apps/partners/components/AddressForm";
import SalesOfficeTooltips from "@/apps/partners/tooltips/sales_office_tooltips";
import SalesAgents from "@/apps/partners/views/SalesAgents";

const _ = require('lodash')

const queryVendors = gql`
query queryVendors($q: String){
  vendors(q: $q, order: "name") {
  data {
    nid
    name
  }
  }
}
`

export default {
  name: "SalesOfficeForm",
  props: ["salesOfficeIn", "vendorNid"],
  components: {
    FormDetailSection,
    PostalCodeSelector,
    OpeningHoursForm,
    SalesAgents,
    AddressForm,
  },
  data() {
    return {
      tooltips: SalesOfficeTooltips,
      tooltipDelay: 500,
      vendors: [],
      vendorQ: null,
      genderLabels: {f: 'female', m: 'male'},
      msHtmlInstantHandoverTemplateOptions: ['ms_html_instant_handover', 'ms_html_instant_handover_no_domain'],
      msCsvHandoverScheduleOptions: ['instant', 'daily'],
      msCsvHandoverFormatOptions: ['massivhaus_csv', 'hanse_csv', 'hebel_csv', 'gussek_csv', 'fingerhut_csv'],
      msCsvHandoverTemplateOptions: ['ms_csv_handover_massivhaus', 'ms_csv_handover_no_domain'],
      showSalesAgents: true,
      showContact: false,
      showOpeningHours: false,
      showBranding: false,
      showReporting: false,
      showHandover: false,
      showMsHandover: false,
      showImmowelt: false,
      msHtmlInstantHandoverEmails: [],
      msCsvHandoverEmails: [],
    }
  },
  methods: {
    updateItem() {
      const data = _.cloneDeep(_.omit(this.salesOffice, ['officeProfilePicture.__typename']))
      //multiselect object
      data.vendor = this.salesOffice.vendor ? {nid: this.salesOffice.vendor.nid} : null;
      data.country = this.salesOffice.country ? {nid: this.salesOffice.country.nid} : null;
      data.postalCode = this.salesOffice.postalCode ? {nid: this.salesOffice.postalCode.nid} : null;
      data.displayRegions = this.salesOffice.displayRegions ? this.salesOffice.displayRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.msDisplayRegions = this.salesOffice.msDisplayRegions ? this.salesOffice.msDisplayRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.imDisplayRegions = this.salesOffice.imDisplayRegions ? this.salesOffice.imDisplayRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.handoverRegions = this.salesOffice.handoverRegions ? this.salesOffice.handoverRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.msHandoverRegions = this.salesOffice.msHandoverRegions ? this.salesOffice.msHandoverRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      data.imHandoverRegions = this.salesOffice.imHandoverRegions ? this.salesOffice.imHandoverRegions.map(item => {
        return item.numericPostalCode
      }) : null;
      this.$emit('update-item', data);
    },
    notNull(val) {
      return val ? val.length >= 1 : false
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    catchError(event) {
      this.error = event
    },
    updateVendorQ(event) {
      this.vendorQ = event
    },
    toggleSalesAgents() {
      this.showSalesAgents = !this.showSalesAgents
    },
    toggleContact() {
      this.showContact = !this.showContact
    },
    toggleOpeningHours() {
      this.showOpeningHours = !this.showOpeningHours
    },
    toggleBranding() {
      this.showBranding = !this.showBranding
    },
    toggleHandover() {
      this.showHandover = !this.showHandover
    },
    toggleReporting() {
      this.showReporting = !this.showReporting
    },
    toggleImmowelt() {
      this.showImmowelt = !this.showImmowelt
    },
    setDefaultOpeningHours(item) {
      if (!item || !item.openingHoursStatus) {
        item = {
          ...item,
          openingHoursStatus: 'by_appointment',
        };
      }
      return item;
    },
    updateOpeningHours(item) {
      this.salesOffice[`openingHours${item.weekday}Status`] = item[`openingHoursStatus`];
      this.salesOffice[`openingHours${item.weekday}Start`] = item[`openingHoursStatus`] === 'open' ? item[`openingHoursStart`] : null;
      this.salesOffice[`openingHours${item.weekday}End`] = item[`openingHoursStatus`] === 'open' ? item[`openingHoursEnd`] : null;
      this.salesOffice[`lunchBreak${item.weekday}Start`] = item[`openingHoursStatus`] === 'open' ? item[`lunchBreakStart`] : null;
      this.salesOffice[`lunchBreak${item.weekday}End`] = item[`openingHoursStatus`] === 'open' ? item[`lunchBreakEnd`] : null;
      this.updateItem();
    },
    updateAddress(address) {
      this.salesOffice['addressLine1'] = address['addressLine1'];
      this.salesOffice['addressLine2'] = address['addressLine2'];
      this.salesOffice['postalCode'] = address['postalCode'];
      this.salesOffice['city'] = address['city'];
      this.salesOffice['country'] = address['country'];
      this.updateItem();
    },
    removeService(service) {
      this.salesOffice.services = this.salesOffice.services.filter(item => item !== service);
      this.updateItem();
    },
    addService(service) {
      this.salesOffice.services = this.salesOffice.services ? [service].concat(this.salesOffice.services) : [service];
      this.updateItem();
    },
    handoverToDisplayRegions() {
      this.salesOffice.displayRegions = this.salesOffice.handoverRegions ? _.cloneDeep(this.salesOffice.handoverRegions) : [];
      this.updateItem();
    },
    handoverToMsDisplayRegions() {
      this.salesOffice.msDisplayRegions = this.salesOffice.msHandoverRegions ? _.cloneDeep(this.salesOffice.msHandoverRegions) : [];
      this.updateItem();
    },
    handoverToImDisplayRegions() {
      this.salesOffice.imDisplayRegions = this.salesOffice.imHandoverRegions ? _.cloneDeep(this.salesOffice.imHandoverRegions) : [];
      this.updateItem();
    },
    uploadOfficeProfilePicture(input) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.salesOffice.officeProfilePicture = this.salesOffice.officeProfilePicture ? this.salesOffice.officeProfilePicture : {};
        this.salesOffice.officeProfilePicture.base64 = e.target.result;
        this.salesOffice.officeProfilePicture.name = input[0].name;
        this.updateItem();
      };
      reader.readAsDataURL(input[0]);
    },
    deleteOfficeProfilePicture() {
      this.salesOffice.officeProfilePicture = {};
      this.updateItem();
    },
    addMsHtmlInstantHandoverEmail(email) {
      this.msHtmlInstantHandoverEmails.push(email);
      this.salesOffice.msHtmlInstantHandoverEmails.push(email);
      this.updateItem();
    },
    addMsCsvHandoverEmail(email) {
      this.msCsvHandoverEmails.push(email);
      this.salesOffice.msCsvHandoverEmails.push(email);
      this.updateItem();
    },
  },
  apollo: {
    vendors: {
      query: queryVendors,
      variables() {
        return {
          q: this.vendorQ
        }
      },
      error(error) {
        console.error(error);
        this.$emit("error", JSON.stringify(error));
      }
    },
  },
  computed: {
    salesOffice() {
      return this.salesOfficeIn ? this.salesOfficeIn : {}
    },
    address() {
      return {
        addressLine1: this.salesOffice ? this.salesOffice.addressLine1 : null,
        addressLine2: this.salesOffice ? this.salesOffice.addressLine2 : null,
        postalCode: this.salesOffice ? this.salesOffice.postalCode : null,
        city: this.salesOffice ? this.salesOffice.city : null,
        country: this.salesOffice ? this.salesOffice.country : null,
      }
    },
    allowEdit: {
      get() {
        return this.$store.state.allowEdit
      },
      set() {
      }
    },
    openingHours() {
      const weekdays = [
        'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday',
        'Sunday'
      ]
      let openingHoursArray = []
      if (!this.salesOffice) {
        openingHoursArray = weekdays.map(day => {
          let out = {weekday: day};
          out[`openingHoursStatus`] = 'closed'
          out[`openingHoursStart`] = null
          out[`lunchBreakEnd`] = null
          out[`lunchBreakStart`] = null
          out[`openingHoursEnd`] = null
          return out
        })
      } else {
        openingHoursArray = weekdays.map(day => {
          let out = {weekday: day};
          out[`openingHoursStatus`] = this.salesOffice[`openingHours${day}Status`]
          out[`openingHoursStart`] = this.salesOffice[`openingHours${day}Start`]
          out[`lunchBreakEnd`] = this.salesOffice[`lunchBreak${day}End`]
          out[`lunchBreakStart`] = this.salesOffice[`lunchBreak${day}Start`]
          out[`openingHoursEnd`] = this.salesOffice[`openingHours${day}End`]
          return out
        })
      }
      return openingHoursArray
    }
  },
  watch: {
    salesOfficeIn() {
      this.updateItem();
    },
    salesOffice() {
      this.updateItem();
    }
  }
}
</script>

<style scoped>
.multiselect {
  border-width: 2pt;
  list-style-type: none;
  margin-bottom: 1pt;
}

.services-multiselect {
  overflow: auto;
  height: 8.75rem;
}

.ms-row {
  margin-bottom: .75em;
}

.switch {
  margin-bottom: .2em;
  margin-top: .15em;
}

.ta-description {
  height: 10px;
}

.multiselect-recipients {
  margin-bottom: 2pt;
  overflow: auto;
  height: auto;
}

.multiselect--active {
  border-width: 2pt;
  list-style-type: none;
  margin-bottom: 1pt;
  overflow: visible;
}

.handover-spec-row {
  min-height: 6rem;
}
.badge-ms {
  background-color: #9c0725 !important;
  white-space: normal !important;
  color: #fff !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-pw {
  background-color: #0083aa !important;
  white-space: normal !important;
  color: #fff !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-im {
  background-color: #fc0 !important;
  white-space: normal !important;
  color: #303038 !important;
  border: 1px solid #ffffff !important;
  margin-right: 0.25rem !important;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
